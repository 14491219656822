/* Mixins */
.alarm-event-drawer .camera-image {
  cursor: pointer;
}
.alarm-event-drawer .jtl-link {
  margin-left: 10px;
}
.alarm-event-drawer-image-modal.ant-modal .ant-modal-header {
  border-bottom: none;
}
.alarm-event-drawer-image-modal.ant-modal .ant-modal-body {
  padding: 0;
}
.alarm-event-drawer-camera-modal .ant-modal-body {
  padding: 0;
  margin-top: -1px;
}
.alarm-event-drawer-camera-modal .jtl-video-wrapper {
  width: 800px;
  height: 450px;
}